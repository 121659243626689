import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.sass'
import { useStaticQuery, graphql, withPrefix } from 'gatsby'

const TemplateWrapper = ({ children }) => {
  const { site, allFile } = useStaticQuery(
    graphql`
      query DEFAULT {
        site {
          siteMetadata {
            title
            description
          }
        }
        allFile(filter: {relativePath: {glob: "*bottom-corner.png"}}) {
          edges {
            node {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )
  return (
    <div>
    <div className="bottom-corner-logo" style={{
        backgroundImage: `url(${allFile.edges[0].node.childImageSharp.fluid.src})`
    }}>
        
      
      <Helmet>
        <html lang="en" />
        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={site.siteMetadata.title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
    </div>
  )
}

export default TemplateWrapper
