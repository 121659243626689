import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
// import Image from "../components/image"
import Img from "gatsby-image"
import SomeSiteMetadata from "../../components/SiteMetadata"



class Form extends React.Component {

  constructor() {
    super();
    this.submitform = this.submitform.bind(this);
    this.state = {
      className: 'alert alert-info invisible',
      responseMessage: ''
    }
  }


  render() {
    return (
      <div>
        <form id="js-form" action="https://t0efv4y1md.execute-api.eu-west-1.amazonaws.com/dev/agribiz-mailer" method="POST">
          <div className="field">
            <label className="label" htmlFor="name">Name</label>
            <div className="control">
              <input className="input" name="name" type="text" placeholder="Name..." />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="reply_to">email</label>
            <div className="control">
              <input className="input" name="reply_to" type="email" placeholder="your_email@address.com" />
            </div>
          </div>
          <div className="field verify">
            <label className="label" htmlFor="verify">verify email</label>
            <div className="control">
              <input className="input" name="verify" type="email" placeholder="your_email@address.com" />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="phone">Phone</label>
            <div className="control">
              <input className="input" name="phone" type="text" placeholder="+34..." />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="company">Company</label>
            <div className="control">
              <input className="input" name="company" type="text" placeholder="Company..." />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="message">Message</label>
            <div className="control">
              <textarea className="textarea" name="message" placeholder="Textarea"></textarea>
            </div>
          </div>
          <div className="field is-grouped">
            <div className="control">
              <button onClick={this.submitform} className="button is-black">Send</button>
            </div>
          </div>
        </form>
        <div className={this.state.className} role="alert"><p id="js-form-response">{this.state.responseMessage}</p></div>
      </div>
    );
  }

  submitform(e) {
    e.preventDefault();

    const form = e.target.form
    let className = this.state.className;
    let responseMessage = this.state.responseMessage;

    // Prepare data to send
    const formData = {};
    const formElements = Array.from(form);
    formElements.map(input => (formData[input.name] = input.value));

    // Log what our lambda function will receive
    console.log(JSON.stringify(formData));

    // Construct an HTTP request
    var xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action, true);
    xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

    // Send the collected formData as JSON
    xhr.send(JSON.stringify(formData));

    // Callback function
    xhr.onloadend = response => {
      if (response.target.status === 200) {
        // The form submission was successful
        form.reset();
        className = "alert alert-info";
        responseMessage = "Thanks for the message. We'll be in touch shortly.";
      } else {
        // The form submission failed
        // alert.classList.remove("invisible");
        // alert.classList.remove("alert-info");
        // alert.classList.add("alert-danger");
        className = "alert alert-danger";
        responseMessage = 'Something went wrong';
        //console.error(JSON.parse(response.target.response).message);
      }
      this.setState({ className })
      this.setState({ responseMessage })
    };
  }
}



const IndexPage = ({ data }) => (

  <Layout>
    <SomeSiteMetadata title="Contact | Agribiz Ltd Kingdom of Bahrain" description="Contact Agribiz to find out more about our products" />
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Contact Agribiz</h1>
          <Form />
        </div>
      </div>
    </section>
  </Layout>


)


export default IndexPage
