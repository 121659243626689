// import { graphql, useStaticQuery } from 'gatsby'

// const useSiteMetadata = () => {
//   const { site } = useStaticQuery(
//     graphql`
//       query SITE_METADATA_QUERY {
//         site {
//           siteMetadata {
//             title
//             description
//           }
//         }
//       }
//     `
//   )
//   return site.siteMetadata
// }

// export default useSiteMetadata










// import React, {Component} from "react"
// import PropTypes from "prop-types"
// import Helmet from "react-helmet"
// import { graphql, useStaticQuery } from 'gatsby'


// export class SiteMetadata extends Component () {
//   constructor(props) {
//     super(props);
//   }
  
//   render() {
//     console.log(this.props)
//   const { site } = useStaticQuery(
//     graphql`
//       query {
//         site {
//           siteMetadata {
//             title
//             description
//           }
//         }
//       }
//     `
//   )

//   const metaDescription = this.props.description || site.siteMetadata.description

//   return (
//     <Helmet
//       htmlAttributes={
//         this.props.lang}
//       title={this.props.title}
//       titleTemplate={`%s | ${site.siteMetadata.title}`}
//       meta={[
//         {
//           name: `description`,
//           content: metaDescription,
//         },
//       ].concat(this.props.meta)}
//     />
//   )
//   }
// }

// SiteMetadata.defaultProps = {
//   lang: 'en',
//   meta: [],
//   description: ''
// }

// SiteMetadata.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired
// }




// const someSiteMetadata = ({ description, lang, meta, title }) => {
//   const { site } = useStaticQuery(
//     graphql`
//       query {
//         site {
//           siteMetadata {
//             title
//             description
//           }
//         }
//       }
//     `
//   )
//   const metaDescription = description || site.siteMetadata.description
//   return (
//     <Helmet
//       htmlAttributes={{
//         lang,
//       }}
//       title={title}
//       titleTemplate={`%s | ${site.siteMetadata.title}`}
//       meta={[
//         {
//           name: `description`,
//           content: metaDescription,
//         },
//       ].concat(meta)}
//     />
//   )
// }

// someSiteMetadata.defaultProps = {
//   lang: `en`,
//   meta: [],
//   description: ``,
//   title: ``,
// }

// someSiteMetadata.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
// }



// export default someSiteMetadata







/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SomeSiteMetadata = ({ description, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  return (
        <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
      ].concat(meta)}
    />
  )
}

SomeSiteMetadata.defaultProps = {
  lang: `en`,
  meta: [],
  description: `Blah`,
}

SomeSiteMetadata.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SomeSiteMetadata
